'use client';

import { TRACKED_EVENTS } from '@/constants';
import { TeamRequest } from '@spikemark/rest-api';
import { Session } from 'next-auth';
import { signOut } from 'next-auth/react';
import { useRouter } from 'next/navigation';
import { FC, useCallback } from 'react';
import { tracker } from '../tracker';
import { useFetch } from '@spikemark/shared-hooks';
import { MascotWithName } from '@spikemark/ui/components/mascot-with-name';
import { Button } from '@spikemark/ui/components/ui/button';
import classnames from 'classnames';
export const Signout: FC<{
  user: Session['user'];
  altStyle?: boolean;
}> = ({
  altStyle,
  user
}) => {
  const {
    push
  } = useRouter();
  const handleSignOut = useCallback(async () => {
    tracker.track(TRACKED_EVENTS.auth.loggedOut);
    await signOut({
      redirect: false,
      callbackUrl: '/tournaments'
    });
    push(`/auth/signout?redirectUri=${encodeURIComponent(`${window.location.origin}/api/auth/callback/cognito`)}`);
  }, [push]);

  // TODO fix this. If there are no user schools, a non existent endpoint is called and a json error is thrown in the browser console
  const {
    data: school
  } = useFetch<TeamRequest>(`/api/teams/${user.schools?.length ? user.schools[0]?.id : ''}`, 'GET', {
    skip: !user.schools || user.schools.length === 0
  });
  return <div className={classnames('flex items-center space-x-2', {
    'text-white': altStyle
  })} data-sentry-component="Signout" data-sentry-source-file="signout.tsx">
      <MascotWithName alt={user.name || ''} path={user.image || school?.schoolLogo || ''} variant="small" displayMode={user.image ? 'player' : 'team'} className="pr-4 border-r" data-sentry-element="MascotWithName" data-sentry-source-file="signout.tsx">
        {user.name || user.email}
      </MascotWithName>
      <Button variant="ghost" size="sm" onClick={handleSignOut} data-sentry-element="Button" data-sentry-source-file="signout.tsx">
        Sign out
      </Button>
    </div>;
};