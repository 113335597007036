export const TRACKED_EVENTS = {
  commonEvents: {
    pageView: 'PageView',
    dropdownChange: 'Action:DropdownChange',
    tabChange: 'Action:TabChange',
    valueChange: 'Action:ValueChange',
    internalLinkClick: 'Action:ClickInternalLink',
    externalLinkClick: 'Action:ClickExternalLink',
    internalNavigate: 'Action:Navigate',
  },
  /** User initiated actions */
  actions: {
    createTournament: 'Action:CreateTournament',
    editTournament: 'Action:EditTournament',
    deleteTournament: 'Action:DeleteTournament',
    saveTournamentScores: 'Action:SaveTournamentScores',
    publishTournament: 'Action:PublishTournament',
    confirmPublishTournament: 'Action:ConfirmPublishTournament',
    addSchool: 'Action:AddSchool',
    removeSchool: 'Action:RemoveSchool',
    addTeamInvitation: 'Action:AddTeamInvitation',
    addIndividualInvitation: 'Action:AddIndividualInvitation',
    removeTeamInvitation: 'Action:RemoveTeamInvitation',
    updateTeamInvitationStatus: 'Action:UpdateTeamInvitationStatus',
    createTeam: 'Action:CreateTeam',
    editTeam: 'Action:EditTeam',
    editTeamRoster: 'Action:EditTeamRoster',
    deleteTeam: 'Action:DeleteTeam',
    createPlayer: 'Action:CreatePlayer',
    editPlayer: 'Action:EditPlayer',
    removePlayer: 'Action:RemovePlayer',
    tieBreak: 'Action:TieBreak',
    cancelAction: 'Action:Cancel',
    confirmDelete: 'Action:ConfirmDelete',
    submitTransferRequest: 'Action:SubmitTransferRequest',
    submitRoster: 'Action:SubmitRoster',
    openLiveScoringModal: 'Action:OpenLiveScoringModal',
    closeLiveScoringModal: 'Action:CloseLiveScoringModal',
  },
  /** User authentication events  */
  auth: {
    /** User logged in to app */
    loggedIn: 'LoginSuccess',
    /** User initiates logout */
    loggedOut: 'Clicked:LogOut',
  },
};

export const TRACKED_PROPERTIES = {
  /** User identification */
  user: {
    id: 'ID',
    roles: 'ROLES',
    name: '$name',
    schools: 'SCHOOLS',
  },
};
