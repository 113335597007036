import type { AccessPlans } from '@spikemark/onboarding/types';
import { UserRoleEnum } from '@spikemark/rest-api';

export enum SpikemarkAuthRole {
  Admins = 'Admins',
  Coaches = 'Coaches',
  Committee = 'Committee',
  Hosts = 'Hosts',
  Players = 'Players',
  Scorers = 'Scorers',
}

export enum SpikemarkResource {
  Tournaments = 'Tournaments',
  Teams = 'Teams',
  Players = 'Players',
  Users = 'Users',
  Clubs = 'Clubs',
  CommitteeVisualisations = 'CommitteeVisualisations',
  Rankings = 'Rankings',
  Analytics = 'Analytics',
}

export type SpikemarkUser = {
  id: string;
  name?: string | null;
  image?: string | null;
  roles: SpikemarkAuthRole[];
  email: string;
  userId: string;
  tableauToken?: string;
  schools?: {
    id: string;
    plan: AccessPlans;
    onboardingStatus?: string;
    subscriptionId?: string;
  }[];
  hosts?: string[];
  access?: UserAccessMap;
  userRole?: UserRoleEnum;
};

export enum AccessScope {
  None,
  Own,
  Any,
}

export type AnyResource = 'any';
export type CRUDAction = 'create' | 'read' | 'update' | 'delete' | 'any';
export type AccessGrant<T = any> = {
  granted: boolean;
  restrict?: string[];
  refine?: (data: T) => boolean;
};
export type UserAccessMap = Partial<
  Record<
    AnyResource | SpikemarkResource,
    Record<CRUDAction | string, Partial<Record<AccessScope, AccessGrant>>>
  >
>;

export type AuthRequest = {
  username?: string;
  password?: string;
  sub?: string;
  refreshToken?: string;
  accessCode?: string;
};

export type AuthWithPasswordRequest = Pick<AuthRequest, 'username' | 'password'>;
export type AuthWithAccessCodeRequest = Pick<AuthRequest, 'accessCode'>;
export type AuthWithRefreshTokenRequest = Pick<AuthRequest, 'sub' | 'refreshToken'>;

export type AuthSignOutReason =
  | 'tournament-does-not-exist'
  | 'cannot-refresh-token'
  | 'authentication-failed'
  | 'silent-login-failed-no-auth'
  | 'user-initiated'
  | 'user-initiated-tournament-complete';

export type AuthResponse = {
  supabaseToken: string;
  supabaseProfile: SupabaseContext;
} & (
  | {
      loginType: 'code';
    }
  | {
      loginType: 'user';
      user: SpikemarkUser;
      sub: string;
      idToken: string;
      accessToken: string;
      refreshToken: string;
      expiresIn: number;
    }
);

export type SupabaseContext = {
  teamIds?: number[];
  hostIds?: string[];
  roles: SpikemarkAuthRole[];
  tournamentId?: number;
  userRole?: UserRoleEnum;
};

export type SupabaseTokenPayload = {
  roles: SpikemarkAuthRole[];
  hosts?: string[];
  schools?: { id: string }[];
  userRole?: UserRoleEnum;
};
