'use client';

import { FC } from 'react';
import { Button } from '@spikemark/ui/components/ui/button';
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuTrigger } from '@spikemark/ui/components/ui/dropdown-menu';
import Link from 'next/link';
import { CircleHelp, MessageSquareText } from 'lucide-react';
import { useClientConfig } from '@spikemark/core';
export const SupportMenu: FC = () => {
  const clientConfig = useClientConfig();
  return <DropdownMenu data-sentry-element="DropdownMenu" data-sentry-component="SupportMenu" data-sentry-source-file="support-menu.tsx">
      <DropdownMenuTrigger asChild className="border-none" data-sentry-element="DropdownMenuTrigger" data-sentry-source-file="support-menu.tsx">
        <Button variant="ghost" data-sentry-element="Button" data-sentry-source-file="support-menu.tsx">Help & Support</Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent data-sentry-element="DropdownMenuContent" data-sentry-source-file="support-menu.tsx">
        <DropdownMenuItem className="cursor-pointer" id="intercom-launcher" data-sentry-element="DropdownMenuItem" data-sentry-source-file="support-menu.tsx">
          <MessageSquareText className="size-4 mr-2" data-sentry-element="MessageSquareText" data-sentry-source-file="support-menu.tsx" /> Chat with us
        </DropdownMenuItem>
        <DropdownMenuItem data-sentry-element="DropdownMenuItem" data-sentry-source-file="support-menu.tsx">
          <Link href={clientConfig.helpCentreUrl} className="flex items-center" data-sentry-element="Link" data-sentry-source-file="support-menu.tsx">
            <CircleHelp className="size-4 mr-2" data-sentry-element="CircleHelp" data-sentry-source-file="support-menu.tsx" />
            Visit our Help Center
          </Link>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>;
};