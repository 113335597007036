import { SchoolTeam } from '@spikemark/rest-api';
import Stripe from 'stripe';

export enum SUPPORTED_CHECKOUT_FLOWS {
  LIVESCORING = 'livescoring',
  ONBOARDING = 'onboarding',
}

export enum CheckoutViews {
  CATALOGUE = 'catalogue',
  SUMMARY = 'summary',
  FINALISE = 'finalise',
  CARD_CAPTURE = 'card',
}

export type LiveScoringCheckoutItem = {
  priceId: string;
  tournamentId: string;
  tournamentDate: string;
};
export type OnboardingCheckoutItem = {
  priceId: string;
  existingSubscriptionId?: string;
  teamId: string;
  plan: AccessPlans;
  gender: 'Men' | 'Women';
};

export type PriceItem<T> = {
  type: 'invoice' | 'charge';
  invoice: Stripe.Invoice;
  item?: T;
};

export enum StripePlans {
  NONE = 'none',
  ENTRY = 'entry',
  BASIC = 'basic',
  STANDARD = 'standard',
  PRO = 'pro',
}

export enum AccessPlans {
  NONE = '0',
  ENTRY = '1',
  BASIC = '2',
  STANDARD = '3',
  PRO = '4',
}

export type OnboardingTeam = {
  details: SchoolTeam;
  plan: AccessPlans;
  existingSubscriptionId?: string;
};
