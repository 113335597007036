'use client';

import { AuthApi } from '@/core/auth/client';
import { SpikemarkAuthRole, schoolRedirects } from '@spikemark/auth';
import { AccessPlans } from '@spikemark/onboarding/types';
import { useSession } from 'next-auth/react';
import { redirect } from 'next/navigation';
export const SubscriptionMiddleware = ({
  children,
  enabled = false
}) => {
  const {
    data: session
  } = useSession();
  if (!enabled) {
    return children;
  }
  const roles = session?.user.roles ?? [];
  const schools = session?.user.schools ?? [];
  const hasSchools = schools.length;
  const hasNoPaidSchools = schools.filter(({
    plan
  }) => plan !== AccessPlans.NONE).length === 0;
  if (roles.includes(SpikemarkAuthRole.Admins)) {
    return children;
  } else {
    if (hasSchools && hasNoPaidSchools) {
      return redirect('/welcome');
    }
    const inferredRedirect = schoolRedirects(schools);
    if (inferredRedirect && inferredRedirect !== '/') {
      return redirect(inferredRedirect);
    }
    return children;
  }
};