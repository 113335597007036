'use client';

import { useEffect } from 'react';
import { usePathname, useSearchParams } from 'next/navigation';
import { TRACKED_EVENTS } from '@/constants';
import { tracker } from '../tracker';
export const PageTracker = () => {
  const pathname = usePathname() ?? '/';
  const searchParams = useSearchParams();
  useEffect(() => {
    tracker.track(TRACKED_EVENTS.commonEvents.pageView, {
      query: searchParams.toString()
    });
  }, [pathname, searchParams]);
  return <></>;
};