import { AccessPlans } from '@spikemark/onboarding/types';

type SpikemarkUserSchool = {
  id: string;
  plan: AccessPlans;
  onboardingStatus?: string;
  subscriptionId?: string;
};

const singleSchoolRedirect = (school: SpikemarkUserSchool) => {
  if (school.onboardingStatus === 'NONE' || school.onboardingStatus === 'TEAM') {
    return `/setup/${school.id}/details`;
  }
  if (school.onboardingStatus === 'ROSTER') {
    return `/setup/${school.id}/roster`;
  }
};

export const schoolRedirects = (schools: SpikemarkUserSchool[]) => {
  if (!schools) {
    return '/';
  }
  const nonCompletedSchools = schools.filter((school) => school.onboardingStatus !== 'COMPLETED');

  if (!nonCompletedSchools.length) {
    return '/';
  }

  const d = singleSchoolRedirect(nonCompletedSchools[0]);
  console.log(d);
  return d;
};
