import(/* webpackMode: "eager", webpackExports: ["SubscriptionMiddleware"] */ "/app/apps/admin/app/subscription-middleware.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["PageTracker"] */ "/app/apps/admin/components/shell/page-tracker.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Signout"] */ "/app/apps/admin/components/shell/signout.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["SupportMenu"] */ "/app/apps/admin/components/shell/support-menu.tsx");
;
import(/* webpackMode: "eager" */ "/app/apps/admin/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/apps/admin/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/cache/client-cache-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/core/src/config/client-config-provider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["useLazyRemote"] */ "/app/packages/shared-hooks/src/hooks/fetch/use-lazy-remote.ts");
;
import(/* webpackMode: "eager", webpackExports: ["useRemote"] */ "/app/packages/shared-hooks/src/hooks/fetch/use-remote.ts");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/assets/scoreboard_logo_alt_centered_white.png");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/assets/scoreboard_logo_alt_centered.png");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/assets/scoreboard_logo.png");
;
import(/* webpackMode: "eager", webpackExports: ["NavLinks"] */ "/app/packages/ui/shell/navigation/nav-links.tsx");
